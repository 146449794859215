import { Component } from "react";

export default function AsyncComponent(getComponent: Function) {
    class AsyncComponent extends Component {
        static Component: any = null;
        state = { Component: AsyncComponent.Component };
        _isMounted = false;

        componentDidMount() {
            this._isMounted = true;

            if (!this.state.Component) {    
                getComponent()
                    .then((Component: any) => {
                        AsyncComponent.Component = Component
                        if(this._isMounted) {
                            this.setState({ Component })
                        }
                })
            }
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render() {
            const { Component } = this.state
            return Component ? <Component {...this.props} /> : null;
        }
    }
    return AsyncComponent;
}